/**
* Generated automatically at built-time (2025-03-27T20:20:03.876Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem, isPathfinderAware, mangleTemplateProps } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
const templates=props.templates.map(template=>{if (!isPathfinderAware(template.component, template.props)) {return template;} return {...template,props: {...template.props, ...mangleTemplateProps(template.component, template.props, props.pathfinder)}}})
return mergeTemplateItems(defaultTemplate, templates).map(createLazyTemplateItem);
}
Template.mapValueToProps = value => ({ pathfinder: value.pathfinder });
Template.mapValueToTemplateProps = {pageKey: "mquvee-beerserver-olkyl",templateKey: "sites/88-3095b2e8-093c-41e7-85dc-aac120bdc700"};